import React from 'react';
import { Link, graphql } from "gatsby"
import Layout from '../components/layout';
import './blog.css'
import SEO from '../components/SEO'
import Hero from '../components/hero/hero'
import siteConfig from '../../data/siteConfig'
import Wrapper from '../components/wrapper'

function TagsPage(props) {

  const data = props.data.allMarkdownRemark.group
  const title = siteConfig.blogTitle

  return (
    <Layout>
      <SEO title="Blog" />
      <Hero
        heroImg={siteConfig.siteCover}
        title={title}
      />
      <Wrapper>
      <div className="tags">
        <h1>All tags</h1>
        {
          data.map(tag => (
            <Link to={`/${tag.fieldValue}`} >
              {tag.fieldValue} {`(${tag.totalCount})`}
            </Link>
          ))
        }
      </div>
      </Wrapper>
    </Layout>
  )

}
export default TagsPage;


export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }

`
